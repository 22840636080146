import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Tabs, Tab, Grid } from '@mui/material';
import { getDocs, doc, collection, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase/config';
import { format } from 'date-fns';

const TrackEmails = () => {
  const [user] = useAuthState(auth);
  const [emailWaves, setEmailWaves] = useState([]);
  const [selectedWaveIndex, setSelectedWaveIndex] = useState(0);
  const [agencyNames, setAgencyNames] = useState({});
  const [stats, setStats] = useState({
    totalAgencies: 0,
    openRate: 0,
    totalResponses: 0,
  });

  useEffect(() => {
    const fetchEmailWaves = async () => {
      if (user) {
        const emailWavesSnapshot = await getDocs(collection(doc(db, 'users', user.uid), 'email_waves'));
        const waves = emailWavesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEmailWaves(waves.sort((a, b) => b.agencies[0].sent_ts - a.agencies[0].sent_ts)); // Sort by most recent wave

        // Compile statistics
        const totalAgencies = waves.reduce((acc, wave) => acc + wave.agencies.length, 0);
        const totalOpens = waves.reduce((acc, wave) => acc + wave.agencies.reduce((subAcc, agency) => subAcc + (agency.opened_ts?.length || 0), 0), 0);
        const totalResponses = waves.reduce((acc, wave) => acc + wave.agencies.filter(agency => agency.response_ts).length, 0);
        const openRate = totalAgencies > 0 ? (totalOpens / totalAgencies) * 100 : 0;

        setStats({
          totalAgencies,
          openRate: openRate.toFixed(2),
          totalResponses,
        });

        // Fetch agency names
        const agenciesPromises = waves.flatMap(wave =>
          wave.agencies.map(async agency => {
            const agencyDoc = await getDoc(doc(db, `users/${user.uid}/agencies`, agency.agency_id));
            return { agencyId: agency.agency_id, name: agencyDoc.exists() ? agencyDoc.data().name : 'Unknown Agency' };
          })
        );

        const agencyNamesArr = await Promise.all(agenciesPromises);
        const agencyNamesMap = agencyNamesArr.reduce((acc, { agencyId, name }) => {
          acc[agencyId] = name;
          return acc;
        }, {});

        setAgencyNames(agencyNamesMap);
      }
    };
    fetchEmailWaves();
  }, [user]);

  const handleWaveChange = (event, newIndex) => {
    setSelectedWaveIndex(newIndex);
  };

  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), "MM/dd/yyyy 'at' hh:mm a");
  };

  const renderAgencyDetails = (agency) => {
    const sentDate = formatTimestamp(agency.sent_ts);
    const opens = agency.opened_ts?.length || 0;
    const lastOpenDate = agency.opened_ts?.length > 0 ? formatTimestamp(agency.opened_ts[agency.opened_ts.length - 1]) : 'Not Opened';
    const clicks = agency.link_click_ts?.length || 0;
    const lastClickDate = agency.link_click_ts?.length > 0 ? formatTimestamp(agency.link_click_ts[agency.link_click_ts.length - 1]) : 'No Clicks';
    const responseDate = agency.response_ts ? formatTimestamp(agency.response_ts) : 'No Response';
    const agencyName = agencyNames[agency.agency_id] || 'Unknown Agency';

    return (
      <Box key={agency.agency_id} mb={2} p={2} border="1px solid #ccc" borderRadius="8px" bgcolor="#f9f9f9">
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Agency: {agencyName}</Typography>
        <Typography variant="body2"><strong>Sent on:</strong> {sentDate}</Typography>
        <Typography variant="body2"><strong>Email Opens:</strong> {opens} (Last: {lastOpenDate})</Typography>
        <Typography variant="body2"><strong>Response:</strong> {responseDate}</Typography>
      </Box>
    );
  };

  return (
    <Container>
      <Typography variant="h3" mb={4} mt={4} sx={{ fontWeight: 'bold', }}>Track Emails</Typography>

      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={4}>
          <Box p={3} bgcolor="#f1f1f1" borderRadius="8px" textAlign="center">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}># of Emails Sent</Typography>
            <Typography variant="h6">{stats.totalAgencies}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box p={3} bgcolor="#f1f1f1" borderRadius="8px" textAlign="center">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Open Rate</Typography>
            <Typography variant="h6">{stats.openRate}%</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box p={3} bgcolor="#f1f1f1" borderRadius="8px" textAlign="center">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}># of Responses</Typography>
            <Typography variant="h6">{stats.totalResponses}</Typography>
          </Box>
        </Grid>
      </Grid>

      {emailWaves.length > 0 ? (
        <Tabs value={selectedWaveIndex} onChange={handleWaveChange} variant="scrollable" scrollButtons="auto">
          {emailWaves.map((wave, index) => {
            const waveDate = formatTimestamp(wave.agencies[0].sent_ts);
            return (
              <Tab key={wave.id} label={`Email Wave #${index + 1}: ${waveDate}`} />
            );
          })}
        </Tabs>
      ) : (
        <Typography>No email waves found.</Typography>
      )}

      <br></br>

      {emailWaves.length > 0 && (
        <Box mt={4}>
          {emailWaves[selectedWaveIndex].agencies.map(renderAgencyDetails)}
          {/* Display email subject and template if available */}
          {emailWaves[selectedWaveIndex].email_subject && emailWaves[selectedWaveIndex].email_body && (
            <Box mt={2} mb={10} p={2} bgcolor="#e0e0e0" borderRadius="8px">
              <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>Email Subject:</Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>{emailWaves[selectedWaveIndex].email_subject}</Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>Email Body:</Typography>
              <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: emailWaves[selectedWaveIndex].email_body }} />
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
  };

  export default TrackEmails;
