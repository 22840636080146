import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, Box, CircularProgress } from '@mui/material';
import { getDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase/config';
import { useNavigate } from 'react-router-dom';

const PurchaseLists = () => {
  const [user, loading] = useAuthState(auth);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false); // Loading state for checkout button
  const navigate = useNavigate();

  const plans = [
    {
      title: "Theatrical | New York",
      lookup_key: "Theatrical_|_NY-a37ccfa",
      benefits: [
        "Submit to Agencies that focus on Television and Film projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Commercial | New York",
      lookup_key: "Commercial_|_NY-315ac79",
      benefits: [
        "Submit to Agencies that focus on TV Commercials, Online Advertisements and Commercial Print projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Management | New York",
      lookup_key: "Management_|_NY-f661620",
      benefits: [
        "Submit to Talent Managers that focus on your overall career strategy and goals",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Theatrical | Los Angeles",
      lookup_key: "Theatrical_|_LA-03f8995",
      benefits: [
        "Submit to Agencies that focus on Television and Film projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Commercial | Los Angeles",
      lookup_key: "Commercial_|_LA-d534869",
      benefits: [
        "Submit to Agencies that focus on TV Commercials, Online Advertisements and Commercial Print projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Management | Los Angeles",
      lookup_key: "Management_|_LA-0e87320",
      benefits: [
        "Submit to Talent Managers that focus on your overall career strategy and goals",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
  ];

  useEffect(() => {
    const fetchUserPlans = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const purchasedPlans = userDoc.data().purchased_plans || [];
            const available = plans.filter(plan => !purchasedPlans.includes(plan.lookup_key));
            setAvailablePlans(available);
          }
        } catch (error) {
          console.error('Error fetching user plans:', error);
        }
      }
    };

    if (!loading) {
      fetchUserPlans();
    }
  }, [user, loading]);

  const handleSelectPlan = (lookup_key) => {
    const updatedPlans = selectedPlans.includes(lookup_key)
      ? selectedPlans.filter(plan => plan !== lookup_key)
      : [...selectedPlans, lookup_key];
    
    setSelectedPlans(updatedPlans);
  };

  const handleCheckout = async () => {
    setIsCheckoutLoading(true); // Start loading state
    try {
      const response = await fetch('https://api-ippv36w6hq-uc.a.run.app/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lookup_keys: selectedPlans, // The price ID or lookup key
        }),
      });

      const data = await response.json();
      window.location.href = data.url; // Redirect to Stripe Checkout
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsCheckoutLoading(false); // Stop loading state on error
    }
  };

  const renderPlans = () => (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      {availablePlans.map((plan, index) => {
        const [planType, location] = plan.title.split(' | '); // Splitting title to get Plan Type and Location
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderColor: selectedPlans.includes(plan.lookup_key) ? '#880404' : 'grey.300',
                borderWidth: 2,
                borderStyle: 'solid',
                cursor: 'pointer',
                backgroundColor: selectedPlans.includes(plan.lookup_key) ? '#fff0f0' : '#FFFFFF',
                borderRadius: 8,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => handleSelectPlan(plan.lookup_key)}
            >
              <CardContent sx={{ padding: '24px' }}>
                {/* Two-row header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', color: '#880404' }}
                  >
                    {planType} Agencies
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#880404' }}
                  >
                    {location}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#880404', textAlign: 'right' }}
                  >
                    $99/yr
                  </Typography>
                </Box>
                <ul style={{ paddingLeft: '20px', marginTop: '12px' }}>
                  {plan.benefits.map((benefit, i) => (
                    <li key={i}>
                      <Typography variant="body2" sx={{ color: '#555' }}>{benefit}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Container sx={{ py: 6 }}>
      <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#880404', mb: 3 }}>
        Looking to purchase more lists?
      </Typography>
      <Typography variant="body1" sx={{ color: '#555', mb: 5 }}>
        Select the lists you wish to purchase below.
      </Typography>
      {renderPlans()}
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Button
          variant="contained"
          sx={{
            borderRadius: 8,
            padding: '12px 36px',
            backgroundColor: '#880404',
            fontWeight: 'bold',
            fontSize: '16px',
            '&:hover': {
              backgroundColor: '#660303',
            },
          }}
          onClick={handleCheckout}
          disabled={selectedPlans.length === 0 || isCheckoutLoading} // Disable the button when loading
        >
          {isCheckoutLoading ? (
            <CircularProgress size={24} sx={{ color: '#fff' }} /> // Show loading spinner
          ) : (
            'Next' // Show "Next" text when not loading
          )}
        </Button>
      </Box>
    </Container>
  );
};

export default PurchaseLists;
