import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Container, Tabs, Tab, CircularProgress, Button, Typography, Grid, Card, CardContent, Box, List, ListItem, ListItemText } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase/config';
import { format } from 'date-fns';

const Dashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [showStepContent, setShowStepContent] = useState(false);
  const [accountInfo, setAccountInfo] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setOnboardingStatus(userDoc.get("onboarding_status") || '');
            setAccountInfo({
              created_ts: userDoc.get('created_ts'),
              purchased_plans_with_ts: userDoc.get('purchased_plans_with_ts') || [],
            });
            setFirstName(userDoc.get('firstName'));
          } else {
            setOnboardingStatus('');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setOnboardingStatus('');
        }
      }
    };

    if (!loading) {
      fetchUserData();
    }
  }, [user, loading]);

  const updateUserSelectedPlans = async () => {
    try {
      await updateDoc(doc(db, "users", user.uid), {
        selected_plans: selectedPlans,
      });
    } catch (error) {
      console.error("Error during update selected plans:", error);
    }
  };

  const handleCheckout = async () => {
    setLoadingCheckout(true); // Set loading state to true
    try {
      const response = await fetch('https://api-ippv36w6hq-uc.a.run.app/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lookup_keys: selectedPlans,
        }),
      });

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setMessage('Failed to initiate checkout. Please try again later.');
      setLoadingCheckout(false); // Reset loading state on error
    }
  };


  const handleSelectPlan = (lookup_key) => {
    const updatedPlans = selectedPlans.includes(lookup_key)
      ? selectedPlans.filter(plan => plan !== lookup_key)
      : [...selectedPlans, lookup_key];

    setSelectedPlans(updatedPlans);
  };

  const plans = [
    {
      title: "Theatrical | New York",
      lookup_key: "Theatrical_|_NY-a37ccfa",
      benefits: [
        "Submit to Agencies that focus on Television and Film projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Commercial | New York",
      lookup_key: "Commercial_|_NY-315ac79",
      benefits: [
        "Submit to Agencies that focus on TV Commercials, Online Advertisements and Commercial Print projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Management | New York",
      lookup_key: "Management_|_NY-f661620",
      benefits: [
        "Submit to Talent Managers that focus on your overall career strategy and goals",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Theatrical | LA",
      lookup_key: "Theatrical_|_LA-03f8995",
      benefits: [
        "Submit to Agencies that focus on Television and Film projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Commercial | LA",
      lookup_key: "Commercial_|_LA-d534869",
      benefits: [
        "Submit to Agencies that focus on TV Commercials, Online Advertisements and Commercial Print projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Management | LA",
      lookup_key: "Management_|_LA-0e87320",
      benefits: [
        "Submit to Talent Managers that focus on your overall career strategy and goals",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
  ];

  const lookupKeyToPlanTitle = (lookupKey) => {
    const plan = plans.find(plan => plan.lookup_key === lookupKey);
    return plan ? plan.title : 'Unknown Plan';
  };

  const renderPlans = () => (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      {plans.map((plan, index) => {
        const [planType, location] = plan.title.split(' | '); // Splitting title to get Plan Type and Location
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderColor: selectedPlans.includes(plan.lookup_key) ? '#880404' : 'grey.300',
                borderWidth: 2,
                borderStyle: 'solid',
                cursor: 'pointer',
                backgroundColor: selectedPlans.includes(plan.lookup_key) ? '#fff0f0' : '#FFFFFF',
                borderRadius: 8,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => handleSelectPlan(plan.lookup_key)}
            >
              <CardContent sx={{ padding: '24px' }}>
                {/* Two-row header */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 'bold', color: '#880404' }}
                  >
                    {planType} Agencies
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#880404' }}
                  >
                    {location}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold', color: '#880404', textAlign: 'right' }}
                  >
                    $99/yr
                  </Typography>
                </Box>
                <ul style={{ paddingLeft: '20px', marginTop: '12px' }}>
                  {plan.benefits.map((benefit, i) => (
                    <li key={i}>
                      <Typography variant="body2" sx={{ color: '#555' }}>{benefit}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );

  const renderStepContent = () => {
    return (
      <Container>
        <TransitionGroup>
          <CSSTransition key={0} timeout={300} classNames="fade">
            <Box sx={{ py: 5 }}>
              <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#333' }}>
                You qualify for the following plans. Select the ones you'd like to purchase:
              </Typography>
              {renderPlans()}
              <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Button
  variant="contained"
  sx={{
    borderRadius: 8,
    padding: '12px 36px',
    backgroundColor: '#880404',
    fontWeight: 'bold',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#660303',
    },
  }}
  onClick={() => {
    if (selectedPlans && selectedPlans.length > 0) {
      updateUserSelectedPlans();
      handleCheckout();
    } else {
      alert("Please select at least one plan before proceeding.");
    }
  }}
  disabled={selectedPlans.length === 0 || loadingCheckout} // Disable the button when loading
>
  {loadingCheckout ? (
    <CircularProgress size={24} sx={{ color: '#fff' }} /> // Show loading spinner
  ) : (
    'Next' // Show "Next" text when not loading
  )}
</Button>

              </Box>
            </Box>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    );
  };

  const handleContinue = () => {
    setShowStepContent(true);
  };

  if (loading || onboardingStatus === null) {
    return (
      <Container sx={{ textAlign: 'center', py: 6 }}>
        <CircularProgress size={60} sx={{ color: '#880404' }} />
      </Container>
    );
  }

  if (onboardingStatus === "select_plans") {
    return (
      <Container sx={{ textAlign: 'center', py: 6 }}>
        {showStepContent ? (
          renderStepContent()
        ) : (
          <>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#880404' }}>Congrats! You've created a RepSeeker account</Typography>
            <Typography variant="body1" sx={{ mb: 4, color: '#555' }}>You're almost done. Please continue to select your plans.</Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </>
        )}
      </Container>
    );
  }

  if (onboardingStatus === 'completed') {
    return (
      <Container sx={{ py: 6 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#880404', mb: 3 }}>
          Welcome, {firstName}!
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', mb: 5 }}>
          Let's get you represented ASAP. First, educate yourself through the <strong>Representation Prep List</strong>.
          Then, browse through the agencies you can email from your purchased plans in the <strong>Apply for Rep</strong> tab.
          And finally, track how you're doing on the <strong>Track Emails</strong> tab!
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 4 }}>
          <Button
            component={Link}
            to="prep-list"
            variant="outlined"
            sx={{
              justifyContent: 'space-between',
              border: '2px solid #880404',
              borderRadius: 4,
              padding: '16px 24px',
              textAlign: 'left',
              color: '#880404',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#f9f9f9' }
            }}
          >
            Representation Prep List
            <ArrowForwardIosIcon />
          </Button>
          <Button
            component={Link}
            to="apply-for-rep"
            variant="outlined"
            sx={{
              justifyContent: 'space-between',
              border: '2px solid #880404',
              borderRadius: 4,
              padding: '16px 24px',
              textAlign: 'left',
              color: '#880404',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#f9f9f9' }
            }}
          >
            Apply for Rep
            <ArrowForwardIosIcon />
          </Button>
          <Button
            component={Link}
            to="track-emails"
            variant="outlined"
            sx={{
              justifyContent: 'space-between',
              border: '2px solid #880404',
              borderRadius: 4,
              padding: '16px 24px',
              textAlign: 'left',
              color: '#880404',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: '#f9f9f9' }
            }}
          >
            Track Emails
            <ArrowForwardIosIcon />
          </Button>
        </Box>

        {/* Account Info Section */}
        {accountInfo && (
          <Box sx={{ mt: 6, p: 4, backgroundColor: '#f1f1f1', borderRadius: 8 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#880404', mb: 2 }}>
              Account Info
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 2 }}>
              Account Created on {format(new Date(accountInfo.created_ts), "MM/dd/yyyy")}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#880404', mt: 5, mb: 2 }}>
              Purchased Plans
            </Typography>
            <List sx={{ padding: 0 }}>
              {accountInfo.purchased_plans_with_ts.map((plan, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 0',
                    borderBottom: index < accountInfo.purchased_plans_with_ts.length - 1 ? '1px solid #e0e0e0' : 'none'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowForwardIosIcon sx={{ color: '#880404', mr: 2, fontSize: '0.8rem' }} />
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#333' }}>
                          {lookupKeyToPlanTitle(plan.name)}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" sx={{ color: '#666', fontStyle: 'italic' }}>
                          Activated on {format(new Date(plan.ts), "MMM dd, yyyy 'at' hh:mm a")}
                        </Typography>
                      }
                    />
                  </Box>
                </ListItem>
              ))}
            </List>

            {/* Purchase More Lists Button */}
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Button
                component={Link}
                to="/purchase-lists"
                variant="contained"
                sx={{
                  borderRadius: 8,
                  padding: '12px 36px',
                  backgroundColor: '#880404',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#660303',
                  },
                }}
              >
                Purchase More Lists
              </Button>
            </Box>
          </Box>
        )}

        <Outlet />
      </Container>
    );
  }

  return (
    <Container sx={{ textAlign: 'center', py: 6 }}>
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#880404' }}>Welcome to RepSeeker!</Typography>
      <Typography variant="body1" sx={{ mb: 4, color: '#555' }}>Please complete the onboarding process.</Typography>
      <Button
        variant="contained"
        sx={{
          borderRadius: 8,
          padding: '12px 36px',
          backgroundColor: '#880404',
          fontWeight: 'bold',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#660303',
          },
        }}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </Container>
  );
};

export default Dashboard;
