import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#333',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#555',
  fontWeight: 'bold',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const Home = () => {
  return (
    <Container>
              <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      <Title variant="h3">RepSeeker: Connecting Talent with Representation</Title>
      <Typography variant="body1" paragraph align="center">
        Welcome to the RepSeeker dashboard. Here you can access hundreeds of agents and managers in NY & LA, get prepped for representation meetings with agency and manager feedback, and access an industry-preferred customaizable submission template. 
        RepSeeker was built to connect talent with representation. If you're searching for your first agent or already a working actor and entering a new market, the support and connections of an Agent or Manager are necessary to get you auditions for bigger projects (High Profile, High Paying, Cast by Top Directors) that are NOT available to unrepresented talent for Self-Submissions. We've spent countless hours meeting with agents and managers, listening to their needs, expectations and frustrations when signing new talent. Our platform and strategies were created using their direct feedback, to maximize your chances of obtaining representation to elevate your career to the next level 🚀🚀🚀🚀
      </Typography>

      {/* <Section>
        <SubTitle variant="h6">1. Why RepSeeker</SubTitle>
        <List>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="a. Agents vs. Managers Explained"
              secondary="Agents focus on getting you auditions for specific types of projects. Some specialize in Commercials (Television and online advertisements campaigns) and others in Theatrical representation (Television and Film). Agents are required to be licensed and bonded in the state they operate. 



Managers are responsible for guiding your overall acting career. This includes advising you on strategies to build your resume and giving feedback on your headshots and self tape auditions. Most managers will also generate auditions for you as well as make introductions to develop your connections in the entertainment business. "
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="b. User Conduct"
              secondary="You agree to use our website for lawful and legitimate purposes only, and not to use our website in any way that violates any applicable laws or regulations, infringes any intellectual property rights, or interferes with the rights of others."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="c. Account Registration"
              secondary="Some features of our website may require you to create an account and provide personal information. You agree to provide accurate, complete, and up-to-date information when registering for an account."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="d. Acknowledgements"
              secondary="You acknowledge RepSeeker LLC makes no guarantee that you will receive any responses or replies from talent agents and managers listed on the Repseeker.com. You acknowledge that no refund shall be due or owed to you should the agents and managers you contact using RepSeeker do not reply to your e-mail messages. You acknowledge RepSeeker LLC makes no guarantee any you will procure talent representation by using RepSeeker. You acknowledge that no refund shall be due or owed to you if you do not procure talent representation as a result of using RepSeeker. You acknowledge that while best efforts are made to keep the contact list current, there shall on occasion be one or more contacts that are inactive and you are not due a refund of any amount should one or more representation e-mail contacts provided by RepSeeker be invalid, inactive or expired."
            />
          </StyledListItem>
        </List>
      </Section>

      <Section>
        <SubTitle variant="h6">2. Repseeker is a Talent Listing Service</SubTitle>
        <Typography variant="body1" paragraph>
        REPSEEKER IS NOT A TALENT AGENCY AND THIS IS NOT A TALENT AGENCY CONTRACT. ONLY A TALENT AGENT LICENSED PURSUANT TO  SECTION 1700.5 OF THE LABOR CODE MAY ENGAGE IN THE OCCUPATION OF PROCURING, OFFERING, PROMISING, OR ATTEMPTING TO PROCURE EMPLOYMENT OR ENGAGEMENTS FOR AN ARTIST. REPSEELER LLC IS PROHIBITED BY LAW FROM OFFERING OR ATTEMPTING TO OBTAIN AUDITIONS OR EMPLOYMENT FOR YOU. IT MAY ONLY PROVIDE YOU WITH LISTING INFORMATION. FOR MORE INFORMATION, CONSULT CHAPTER 4.5 (COMMENCING WITH SECTION 1701) OF PART 6 OF DIVISION 2 OF THE LABOR CODE. A DISPUTE ARISING OUT OF THE PERFORMANCE OF THE CONTRACT BY THE TALENT SERVICE THAT IS NOT RESOLVED TO THE SATISFACTION OF THE ARTIST/TALENT SHOULD BE REFERRED TO A LOCAL CONSUMER AFFAIRS DEPARTMENT OR LOCAL LAW ENFORCEMENT, AS APPROPRIATE. Pursuant to California state law, Repseeker LLC has posted a $50,000 bond with the labor Commissioner issued by American Contractors Indemnity Company, Bond No. 100789249.        </Typography>
      </Section>

      <Section>
        <SubTitle variant="h6">3. Content and Services</SubTitle>
        <List>
          <StyledListItem>
            <ArrowForwardIosIcon color="secondary" />
            <StyledListItemText
              primary="a. Informational Use"
              secondary="Our website provides general information and is not intended to be a substitute for professional advice or services."
            />
          </StyledListItem>
          <StyledListItem>
            <ArrowForwardIosIcon color="secondary" />
            <StyledListItemText
              primary="b. Third-party Content"
              secondary="Our website may contain links to third-party websites, content, and services. We are not responsible for any third-party content and do not endorse or sponsor any third-party websites or services."
            />
          </StyledListItem>
          <StyledListItem>
            <ArrowForwardIosIcon color="secondary" />
            <StyledListItemText
              primary="c. Service Changes"
              secondary="We reserve the right to modify, suspend, or discontinue our website, content, and services at any time, without prior notice or liability."
            />
          </StyledListItem>
        </List>
      </Section>

      <Section>
        <SubTitle variant="h6">4. Intellectual Property</SubTitle>
        <List>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="a. Ownership"
              secondary="Our website, content, and services are protected by intellectual property laws. We own or license all rights, titles, and interests in and to our website, content, and services."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="b. Prohibited Use"
              secondary="You are not allowed to use our website, content, and services in any way that infringes or violates our intellectual property rights, or the intellectual property rights of others."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="c. Intellectual Property of Purchased Information"
              secondary="Information purchased on Repseeker is protected by intellectual property laws. Republishing, reproducing, or sharing this information in any form or by any means without permission from Repseeker LLC is strictly prohibited and may result in legal action. If you have purchased information on Repseeker, you are granted a non-exclusive, non-transferable license to use the information for your own personal or business purposes only. You are expressly prohibited from reselling, distributing, or sharing the information with any third party without the prior written consent of Repseeker LLC. Any unauthorized use or distribution of this information may result in legal consequences, including but not limited to damages, injunctive relief, and attorneys’ fees."
            />
          </StyledListItem>
        </List>
      </Section>
      <Section>
        <SubTitle variant="h6">6. Account Cancellation</SubTitle>
        <List>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="a. Right to Cancel"
              secondary="You shall have the right to cancel and receive a full refund within ten (10) business days after your initial purchase."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="b. Cancellation Process"
              secondary="In order to cancel you must log into your talent dashboard at reepseeker.com/login and select 'Cancel Membership'."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="c. Refund Requests"
              secondary="Any and all refund requests must be sent via email to support@repseeker.com."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="d. Automatic Renewal"
              secondary="Your account will automatically renew every year (365 days from initial purchase) if not manually cancelled prior to your renewal date."
            />
          </StyledListItem>
        <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="e. As Available Basis"
              secondary="Our website, content, and services are provided on an “as is” and “as available” basis, without any warranties of any kind, express or implied. We do not warrant that our website, content, and services will be error-free, uninterrupted, or free of viruses or other harmful components. We are not responsible for any loss, damage, or harm caused by your use of our website, content, and services."
            />
          </StyledListItem>
        </List>
      </Section>

      <Section>
        <SubTitle variant="h6">7. Limitation of Liability</SubTitle>
        <Typography variant="body1" paragraph>
          We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from or related to your use of our website, content, and services.
        </Typography>
      </Section>

      <Section>
        <SubTitle variant="h6">8. Class Action Waiver</SubTitle>
        <Typography variant="body1" paragraph>
        You agree that any dispute arising from or related to your use of our website, content, and services will be resolved through individual arbitration, and you waive your right to participate in any class action or collective action against Repseeker LLC.        </Typography>
      </Section>

      <Section>
        <SubTitle variant="h6">10. Indemnification</SubTitle>
        <List>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="a. Indemnification Agreement"
              secondary="You agree to indemnify, defend, and hold harmless the Repseeker LLC, its officers, directors, employees, agents, and affiliates (collectively referred to as “Indemnified Parties”) from and against any and all claims, losses, damages, liabilities, costs, and expenses, including but not limited to attorneys’ fees, arising out of or in connection with your use of Repseeker."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="b. Misrepresentation or Breach of Warranty"
              secondary="You shall indemnify the Indemnified Parties for any damages or losses incurred as a result of any misrepresentation or breach of warranty made by you in connection with Repseeker LLC."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="c. Claims or Disputes"
              secondary="You agree to indemnify the Indemnified Parties for any claims or disputes arising from your use of Repseeker.com, including but not limited to claims related to copyright infringement, trademark infringement, defamation, or any other intellectual property or personal rights violations."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="d. Compliance with Laws"
              secondary="You shall indemnify the Indemnified Repseeker LLC for any damages or losses incurred as a result of your failure to comply with applicable laws, regulations, or industry standards in connection with Repseeker LLC."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="e. Actions or Omissions"
              secondary="You shall indemnify the Indemnified Parties for any damages or losses incurred as a result of your actions or omissions, including but not limited to any acts of negligence, fraud, or misconduct, in connection with Repseeker LLC."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="f. Survival of Indemnification Obligations"
              secondary="Your obligation to indemnify the Indemnified Parties shall survive the termination or expiration of any agreement or relationship between you and the Repseeker LLC."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="g. Notification of Claims"
              secondary="Repseeker shall promptly notify you in writing of any claim or demand for which it seeks indemnity under this clause, and you shall have the right to defend such claim or demand at your own expense with counsel of your choosing, provided that the Repseeker LLC shall have the right to participate in the defense of any such claim or demand at its own expense."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="h. Settlement of Claims"
              secondary="You shall not settle any claim or demand for which you seek indemnity under this clause without the prior written consent of Repseeker LLC, which consent shall not be unreasonably withheld."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="i. Limitation of Liability"
              secondary="Your indemnification obligations under this clause shall not be limited by any limitation of liability or exclusion of damages provisions in any agreement between you and Repseeker LLC."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="j. Use of Platform"
              secondary="You acknowledge that Repseeker is providing a platform for the promotion of talent, and that Repseeker LLC does not endorse or guarantee the quality, legality, or reliability of any Agency or Management companies listed on its platform. You agree to use Repseeker.com at your own risk and shall indemnify Repseeker for any damages or losses incurred as a result of your use of Repseeker.com."
            />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="k. Agreement to Indemnify"
              secondary="You acknowledge and agree to the terms of this indemnity clause and agree to fully indemnify and hold harmless Repseeker as set forth herein."
            />
          </StyledListItem>
        </List>
      </Section>

      <Section>
        <SubTitle variant="h6">11. Severability</SubTitle>
        <List>
          <StyledListItem>
            <CheckCircleIcon color="primary" />
            <StyledListItemText
              primary="Severability"
              secondary="If any provision of this Agreement is determined by a court of competent jurisdiction to be unenforceable, illegal, or invalid under California law, the parties agree that such provision shall be severed from this Agreement, and the remaining provisions shall continue in full force and effect to the extent permitted by law. The parties further agree that the court shall have the authority to modify or reform the unenforceable, illegal, or invalid provision to the extent necessary to make it enforceable and consistent with the original intent of the parties, as closely as possible, while preserving the economic and legal objectives of this Agreement."
            />
          </StyledListItem>
        </List>
      </Section>*/}
    </Container>
  );
};

export default Home;