import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html, #root {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  body {
    font-family: 'Roboto', sans-serif;
    flex: 1;
  }
`;

export default GlobalStyles;
