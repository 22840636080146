import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, MenuItem, Checkbox, Grid, Card, FormControlLabel, CardContent, FormGroup, Box } from '@mui/material';
import { GoogleLoginButton } from "react-social-login-buttons";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { doc, updateDoc } from 'firebase/firestore';
import RepseekerLogo from "./repseeker_logo.jpeg";
import './GetStarted.css';
import { gapi } from 'gapi-script';
import { db, auth } from '../firebase/config';

const GetStarted = () => {
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    goal: '',
    representedBefore: '',
    representationDetails: '',
    referralSource: '',
    cities: [],
    representationType: [],
    firstName: '',
    lastName: '',
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    uid: '',
    selectedPlans: [],
  });

  const [selectedPlans, setSelectedPlans] = useState([]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: "1003128171178-uvdlq2pl7lb0b020r5auuvv9jrm7023o.apps.googleusercontent.com",
        scope: "https://mail.google.com",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const handleCheckout = async () => {
    try {
      const response = await fetch('https://api-ippv36w6hq-uc.a.run.app/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lookup_keys: selectedPlans,
        }),
      });

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setMessage('Failed to initiate checkout. Please try again later.');
    }
  };

  const handlePortal = async () => {
    try {
      const response = await fetch('https://api-ippv36w6hq-uc.a.run.app/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: sessionId,
        }),
      });

      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Error creating portal session:', error);
      setMessage('Failed to initiate billing management. Please try again later.');
    }
  };

  const plans = [
    {
      title: "Theatrical | New York",
      lookup_key: "Theatrical_|_NY-a37ccfa",
      benefits: [
        "Submit to Agencies that focus on Television and Film projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Commercial | New York",
      lookup_key: "Commercial_|_NY-315ac79",
      benefits: [
        "Submit to Agencies that focus on TV Commercials, Online Advertisements and Commercial Print projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Management | New York",
      lookup_key: "Management_|_NY-f661620",
      benefits: [
        "Submit to Talent Managers that focus on your overall career strategy and goals",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Theatrical | LA",
      lookup_key: "Theatrical_|_LA-03f8995",
      benefits: [
        "Submit to Agencies that focus on Television and Film projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Commercial | LA",
      lookup_key: "Commercial_|_LA-d534869",
      benefits: [
        "Submit to Agencies that focus on TV Commercials, Online Advertisements and Commercial Print projects",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
    {
      title: "Management | LA",
      lookup_key: "Management_|_LA-0e87320",
      benefits: [
        "Submit to Talent Managers that focus on your overall career strategy and goals",
        "One-click apply",
        "Personalized talent pitch letter",
        "Representation prep list",
        "Account active for 1 year",
      ],
    },
  ];

  const handleSelectPlan = (lookup_key) => {
    const updatedPlans = selectedPlans.includes(lookup_key)
      ? selectedPlans.filter(plan => plan !== lookup_key)
      : [...selectedPlans, lookup_key];

    setSelectedPlans(updatedPlans);
    const updatedFormData = { ...formData, selectedPlans: updatedPlans };
    setFormData(updatedFormData);
    localStorage.setItem('formData', JSON.stringify(updatedFormData));
  };

  const renderPlans = () => (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      {plans.map((plan, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              borderColor: selectedPlans.includes(plan.lookup_key) ? '#880404' : 'grey.300',
              borderWidth: 2,
              borderStyle: 'solid',
              cursor: 'pointer',
              backgroundColor: selectedPlans.includes(plan.lookup_key) ? '#fff0f0' : '#FFFFFF',
              borderRadius: 8,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => handleSelectPlan(plan.lookup_key)}
          >
            <CardContent sx={{ padding: '24px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#880404' }}
                >
                  {plan.title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: '#880404',
                  }}
                >
                  $99/yr
                </Typography>
              </Box>
              <ul style={{ paddingLeft: '20px', marginTop: '12px' }}>
                {plan.benefits.map((benefit, i) => (
                  <li key={i}>
                    <Typography variant="body2" sx={{ color: '#555' }}>{benefit}</Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    localStorage.setItem('formData', JSON.stringify(updatedFormData));
  };

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: formData[name].includes(value)
        ? formData[name].filter(item => item !== value)
        : [...formData[name], value]
    };
    setFormData(updatedFormData);
    localStorage.setItem('formData', JSON.stringify(updatedFormData));
  };

  useEffect(() => {
    const savedStep = parseInt(localStorage.getItem('currentStep'), 10);
    const savedFormData = JSON.parse(localStorage.getItem('formData'));

    if (savedStep) {
      setStep(savedStep);
    }

    if (savedFormData) {
      setFormData(savedFormData);
    }

    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleNext = () => {
    const newStep = step + 1;
    setStep(newStep);
    localStorage.setItem('currentStep', newStep);
    localStorage.setItem('formData', JSON.stringify(formData));
  };

  const handlePrev = () => {
    const newStep = step - 1;
    setStep(newStep);
    localStorage.setItem('currentStep', newStep);
    localStorage.setItem('formData', JSON.stringify(formData));
  };

  const handleGoogleSignUp = async () => {
    const state = encodeURIComponent(JSON.stringify(formData));
    try {
      localStorage.setItem('loggedIn', "false");
      const response = await fetch(`https://api-ippv36w6hq-uc.a.run.app/get-google-auth-url?state=${state}&prompt=consent`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to get auth URL.');
      }

      const width = 500;
      const height = 600;
      const left = (window.screen.width / 2) - (width / 2);
      const top = (window.screen.height / 2) - (height / 2);

      const { authUrl } = await response.json();
      const popup = window.open(
        authUrl,
        "GoogleAuthPopup",
        `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
      );

      if (!popup) {
        throw new Error("Failed to open popup window.");
      }
      const interval = setInterval(() => {
          if (popup.closed) {
            clearInterval(interval);
            const loggedIn = localStorage.getItem('loggedIn');
            if (loggedIn === "true") {
              navigate('/dashboard');
            }
          }
      }, 1000);

    } catch (error) {
      console.error("Error during Google sign-up:", error);
    }
  };

  const updateUserSelectedPlans = async () => {
    try {
      await updateDoc(doc(db, "users", formData.uid), {
        selected_plans: selectedPlans,
      });

      setFormData({ ...formData, selectedPlans: selectedPlans });
      handleNext();
    } catch (error) {
      console.error("Error during update selected plans:", error);
    }
  };

  const ProductDisplay = ({ handleCheckout }) => (
    <section>
      <div className="product">
        <div className="description">
          <h3>Starter plan</h3>
          <h5>$20.00 / month</h5>
        </div>
      </div>
      <button id="checkout-and-portal-button" onClick={handleCheckout}>
        Checkout
      </button>
    </section>
  );

  const SuccessDisplay = ({ sessionId, handlePortal }) => {
    return (
      <section>
        <div className="product Box-root">
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <button id="checkout-and-portal-button" onClick={handlePortal}>
          Manage your billing information
        </button>
      </section>
    );
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>RepSeeker Launchpad</Typography>
            <img src={RepseekerLogo} alt="RepSeeker Talent Agency and Manager Access" style={{ width: '20%', height: 'auto', marginBottom: '32px' }} />
            <Typography variant="body1" sx={{ mb: 6, color: '#555' }}>See if you qualify. Apply in a few minutes.</Typography>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
            >
              Get Started
            </Button>
          </Container>
        );
      case 1:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>Your Representation Tech Stack, All in One Place</Typography>
            <Typography variant="body1" sx={{ mb: 6, color: '#555' }}>
              ✔ Get instant access to a verified list of talent agent emails<br />
              ✔ Send several personalized emails (not cc'd or bcc'd) in a few clicks<br />
              ✔ Track which agents have seen your materials<br />
              ✔ Avoid exorbitant fees that come with showcasing your talent<br />
            </Typography>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
            >
              Next
            </Button>
          </Container>
        );
      case 2:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>What are your goals with RepSeeker?</Typography>
            <TextField
              label="Your Goals"
              name="goal"
              value={formData.goal}
              onChange={handleChange}
              fullWidth
              multiline
              sx={{ mb: 6 }}
              required
            />
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={!formData.goal}
            >
              Continue
            </Button>
          </Container>
        );
      case 3:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>Have you been represented before?</Typography>
            <TextField
              select
              label="Represented Before"
              name="representedBefore"
              value={formData.representedBefore}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 4 }}
              required
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </TextField>
            {formData.representedBefore === 'yes' && (
              <TextField
                label="By Who?"
                name="representationDetails"
                value={formData.representationDetails}
                onChange={handleChange}
                fullWidth
                sx={{ mb: 6 }}
                required
              />
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={!formData.representedBefore || (formData.representedBefore === 'yes' && !formData.representationDetails)}
            >
              Continue
            </Button>
          </Container>
        );
      case 4:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>How did you hear about RepSeeker?</Typography>
            <TextField
              select
              label="Referral Source"
              name="referralSource"
              value={formData.referralSource}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 6 }}
              required
            >
              <MenuItem value="google">Google</MenuItem>
              <MenuItem value="friend">Friend</MenuItem>
              <MenuItem value="social_media">Social Media</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={!formData.referralSource}
            >
              Continue
            </Button>
          </Container>
        );
      case 5:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>Which cities are you open to getting represented in?</Typography>
            <FormGroup sx={{ mb: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="cities"
                    value="NYC"
                    checked={formData.cities.includes('NYC')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="New York City"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="cities"
                    value="LA"
                    checked={formData.cities.includes('LA')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Los Angeles"
              />
            </FormGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={formData.cities.length === 0}
            >
              Continue
            </Button>
          </Container>
        );
      case 6:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>What kind of representation are you looking for?</Typography>
            <FormGroup sx={{ mb: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="representationType"
                    value="theatrical"
                    checked={formData.representationType.includes('theatrical')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Theatrical"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="representationType"
                    value="commercial"
                    checked={formData.representationType.includes('commercial')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Commercial"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="representationType"
                    value="management"
                    checked={formData.representationType.includes('management')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Management"
              />
            </FormGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={formData.representationType.length === 0}
            >
              Continue
            </Button>
          </Container>
        );
      case 7:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>Your Name</Typography>
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 4 }}
              required
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 6 }}
              required
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={!formData.firstName || !formData.lastName}
            >
              Continue
            </Button>
          </Container>
        );
      case 8:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>Birth Date</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 6 }}>
              <TextField
                label="Month"
                name="birthMonth"
                value={formData.birthMonth}
                onChange={handleChange}
                placeholder="MM"
                sx={{ width: '80px', mx: 1 }}
                required
              />
              <TextField
                label="Day"
                name="birthDay"
                value={formData.birthDay}
                onChange={handleChange}
                placeholder="DD"
                sx={{ width: '80px', mx: 1 }}
                required
              />
              <TextField
                label="Year"
                name="birthYear"
                value={formData.birthYear}
                onChange={handleChange}
                placeholder="YYYY"
                sx={{ width: '100px', mx: 1 }}
                required
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={!formData.birthMonth || !formData.birthDay || !formData.birthYear}
            >
              Continue
            </Button>
          </Container>
        );
      case 9:
        return (
          <Container sx={{ textAlign: 'center', py: 6 }}>
            <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>Sign Up with Google</Typography>
            <GoogleLoginButton onClick={handleGoogleSignUp} style={{ marginBottom: '32px' }} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
              disabled={!formData.uid}
            >
              Continue
            </Button>
          </Container>
        );
      case 10:
        return (
          <Container>
            <TransitionGroup>
              <CSSTransition key={step} timeout={300} classNames="fade">
                <Box sx={{ py: 4 }}>
                  <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#880404' }}>
                    Congrats! You qualify for the following plans. Select the ones you'd like to purchase:
                  </Typography>
                  {renderPlans()}
                  <Box sx={{ textAlign: 'center', mt: 6 }}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 8,
                        padding: '12px 36px',
                        backgroundColor: '#880404',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        '&:hover': {
                          backgroundColor: '#660303',
                        },
                      }}
                      onClick={() => {
                        if (selectedPlans && selectedPlans.length > 0) {
                          updateUserSelectedPlans();
                          handleNext();
                        } else {
                          alert("Please select at least one plan before proceeding.");
                        }
                      }}
                      disabled={selectedPlans.length === 0}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </CSSTransition>
            </TransitionGroup>
          </Container>
        );
      case 11:
        return (
          (!success && message === '') ?
            <ProductDisplay handleCheckout={handleCheckout} />
            : (success && sessionId !== '') ?
              <SuccessDisplay sessionId={sessionId} handlePortal={handlePortal} />
              : (
                <Message message={message} />
              )
        );
      default:
        return (
          <Container sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#880404' }}>Get Started</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{
                borderRadius: 8,
                padding: '12px 36px',
                backgroundColor: '#880404',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  backgroundColor: '#660303',
                },
              }}
            >
              Start
            </Button>
          </Container>
        );
    }
  };

  return (
    <Container>
      <TransitionGroup>
        <CSSTransition
          key={step}
          timeout={300}
          classNames="fade"
        >
          {renderStepContent()}
        </CSSTransition>
      </TransitionGroup>
      {step > 0 && (
        <Button
          onClick={handlePrev}
          sx={{
            mt: 2,
            borderRadius: 8,
            color: '#880404',
            fontWeight: 'bold',
          }}
        >
          Back
        </Button>
      )}
    </Container>
  );
};

export default GetStarted;
