// src/components/Footer.js

import React from 'react';
import { Box, Typography, Link, Button, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#000000',
        color: '#FFFFFF',
        padding: '40px 0',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
        Representation made easy.
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '20px' }}>
        For more information, see our <Link href="/terms-of-use" target="_blank" rel="noopener noreferrer" sx={{ color: '#1E90FF' }}>Terms of Service</Link> and <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer" sx={{ color: '#1E90FF' }}>Privacy Policy</Link>.
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: '16px' }}>
        To reach out to support, reach out to <Link href="mailto:support@repseeker.com" sx={{ color: '#1E90FF' }}>support@repseeker.com</Link>.
      </Typography>
      <Typography variant="body2" sx={{ borderTop: '1px solid #FFFFFF', paddingTop: '16px', marginTop: '16px' }}>
        © RepSeeker, 2024.
      </Typography>
    </Box>
  );
};

export default Footer;
