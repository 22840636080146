import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#333',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#555',
  fontWeight: 'bold',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const PrivacyPolicy = () => {
  return (
    <Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      <Title variant="h3">Privacy Policy</Title>
      <Section>
        <Typography variant="h6" gutterBottom>
          Effective Date: 6/01/2024
        </Typography>
        <SubTitle variant="h5">1. Introduction</SubTitle>
        <Typography paragraph>
          Welcome to Repseeker (“we,” “our,” or “us”). We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data when you use our website and services. By accessing or using our website, you consent to the practices described in this Privacy Policy.
        </Typography>
        <SubTitle variant="h5">2. Information We Collect</SubTitle>
        <SubTitle variant="h6">A. Personal Information</SubTitle>
        <Typography paragraph>
          We may collect the following categories of personal information:
        </Typography>
        <List>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Contact Information: Such as your name, email address, postal address, and phone number." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Account Information: If you create an account with us, we may collect your username and password." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Payment Information: If you make purchases through our website, we collect payment card information or other payment details." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Usage Information: We collect data about your interactions with our website, including your IP address, browser type, operating system, and pages visited." />
          </StyledListItem>
        </List>
        <SubTitle variant="h6">B. Cookies and Similar Technologies</SubTitle>
        <Typography paragraph>
          We use cookies and similar technologies to collect information about your browsing activities. You can manage your cookie preferences through your browser settings.
        </Typography>
        <SubTitle variant="h5">3. How We Use Your Information</SubTitle>
        <Typography paragraph>
          We may use your personal information for the following purposes:
        </Typography>
        <List>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="To provide and maintain our services." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="To process transactions and send transaction notifications." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="To communicate with you about updates, promotions, and customer service." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="To personalize your experience and deliver content and product recommendations." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="To improve our website, products, and services." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="To comply with legal obligations." />
          </StyledListItem>
        </List>
        <SubTitle variant="h5">4. Disclosure of Your Information</SubTitle>
        <Typography paragraph>
          We may disclose your personal information to:
        </Typography>
        <List>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Service Providers: We may share your data with third-party service providers who help us operate our website and provide services." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Legal Requirements: We may disclose your information to comply with legal obligations, enforce our policies, or protect our rights, privacy, safety, or property." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Business Transfers: In the event of a merger, acquisition, bankruptcy, or sale of assets, your information may be transferred to a successor entity." />
          </StyledListItem>
        </List>
        <SubTitle variant="h5">5. Your Rights and Choices</SubTitle>
        <Typography paragraph>
          Under the CCPA, California residents have specific rights regarding their personal information. You may:
        </Typography>
        <List>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Request access to your personal information." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Request the deletion of your personal information." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Opt-out of the sale of your personal information (if applicable)." />
          </StyledListItem>
          <StyledListItem>
            <CheckCircleIcon />
            <StyledListItemText primary="Not be discriminated against for exercising your privacy rights." />
          </StyledListItem>
        </List>
        <Typography paragraph>
          To exercise your rights, please contact us using the contact information provided below.
        </Typography>
        <SubTitle variant="h5">6. Data Security</SubTitle>
        <Typography paragraph>
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
        </Typography>
        <SubTitle variant="h5">7. Changes to this Privacy Policy</SubTitle>
        <Typography paragraph>
          We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting a revised Privacy Policy on this page.
        </Typography>
        <SubTitle variant="h5">8. Contact Us</SubTitle>
        <Typography paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Typography>
        <Typography paragraph>
          support@repseeker.com
        </Typography>
      </Section>

    </Container>
  );
};

export default PrivacyPolicy;