import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase/config';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';

const AdminDashboard = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [agencyId, setAgencyId] = useState('');
  const [agentFirstName, setAgentFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lookupKey, setLookupKey] = useState('');
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [removeEmail, setRemoveEmail] = useState('');

  const lookupKeys = [
    'Management_|_NY-f661620',
    'Management_|_LA-0e87320',
    'Commercial_|_LA-d534869',
    'Commercial_|_NY-315ac79',
    'Theatrical_|_LA-03f8995',
    'Theatrical_|_NY-a37ccfa',
  ];

  useEffect(() => {
    if (!loading) {
      if (!user || (user.email !== 'repseekerdev@gmail.com' && user.email !== 'repseekermain@gmail.com')) {
        navigate('/dashboard'); // Redirect to /dashboard if not authenticated or not the correct email
      }
    }
  }, [user, loading, navigate]);

  const handleAddAgency = async () => {
    try {
      const agencyDocRef = doc(db, 'agencies', agencyId);
      const agencyDoc = await getDoc(agencyDocRef);

      if (agencyDoc.exists()) {
        alert('An agency with this ID already exists.');
        return;
      }

      const createdTs = Date.now();
      await setDoc(agencyDocRef, {
        agency_id: agencyId,
        agent_first_name: agentFirstName,
        email,
        lookup_key: lookupKey,
        name,
        url,
        created_ts: createdTs,
      });

      alert('Agency added successfully!');
      setAgencyId('');
      setAgentFirstName('');
      setEmail('');
      setLookupKey('');
      setName('');
      setUrl('');

      // Call the cloud function to further process the agency addition
      await fetch('https://api-ippv36w6hq-uc.a.run.app/add-agency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ agencyId }),
      });
    } catch (error) {
      console.error('Error adding agency:', error);
      alert('Error adding agency.');
    }
  };

  const handleRemoveAgency = async () => {
    // TODO: Implement remove agency
    try {
      await fetch('https://api-ippv36w6hq-uc.a.run.app/remove-agency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: removeEmail }),
      });
    } catch (error) {
      console.error('Error removing agency:', error);
      alert('Error removing agency.');
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard - Add Agency
      </Typography>
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
        <TextField
          label="Agency ID"
          value={agencyId}
          onChange={(e) => setAgencyId(e.target.value)}
          required
        />
        <TextField
          label="Agent First Name"
          value={agentFirstName}
          onChange={(e) => setAgentFirstName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Lookup Key"
          select
          value={lookupKey}
          onChange={(e) => setLookupKey(e.target.value)}
          required
        >
          {lookupKeys.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Agency Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Agency URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
        />
        <Button variant="contained" onClick={handleAddAgency}>
          Add Agency
        </Button>
      </Box>

      <Typography variant="h4" gutterBottom>
        Admin Dashboard - Remove Agency
      </Typography>
      <TextField
          label="Email"
          value={removeEmail}
          onChange={(e) => setRemoveEmail(e.target.value)}
          required
        />
                <Button variant="contained" onClick={handleRemoveAgency}>
          Remove Agency
        </Button>
    </Container>
  );
};

export default AdminDashboard;
