import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Button, Box } from '@mui/material';
import { updateDoc, doc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase/config';

const Checkout = () => {
  const [user, loading] = useAuthState(auth);
  const [loadingKeys, setLoadingKeys] = useState(true); // State to handle loading of lookup keys
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const success = query.get('success');
  const sessionId = query.get('session_id');

  useEffect(() => {
    const fetchAndProcessCheckoutSession = async (sessionId, uid) => {
      try {
        const response = await fetch(`https://api-ippv36w6hq-uc.a.run.app/retrieve-checkout-session/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }), // Send the uid in the request body
        });

        const sessionData = await response.json();

        if (sessionData.success) {
          // Update the user's Firestore document with the purchased lookup_keys
        }

        setLoadingKeys(false);
      } catch (error) {
        console.error('Error processing checkout session:', error);
        setLoadingKeys(false);
      }
    };

    const updateOnboardingStatus = async (status) => {
      if (user) {
        try {
          await updateDoc(doc(db, 'users', user.uid), {
            onboarding_status: status,
          });
        } catch (error) {
          console.error('Error updating onboarding status:', error);
        }
      }
    };

    if (success === 'true' && sessionId && user?.uid) {
      updateOnboardingStatus('completed');
      fetchAndProcessCheckoutSession(sessionId, user.uid); // Pass the uid when fetching and processing the checkout session
    } else if (user?.uid) {
      updateOnboardingStatus('select_plans');
      setLoadingKeys(false); // Set loading to false if payment failed

      // Redirect after 3 seconds if payment failed
      const timer = setTimeout(() => {
        navigate('/dashboard');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [user, success, sessionId, navigate]);

  if (loading || loadingKeys) {
    return (
      <Container sx={{ textAlign: 'center', py: 6 }}>
        <CircularProgress size={60} sx={{ color: '#880404' }} />
        <Typography variant="h6" sx={{ mt: 2, color: '#880404' }}>
          Processing your payment...
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ textAlign: 'center', py: 6 }}>
      {success === 'true' ? (
        <Box sx={{ maxWidth: 600, mx: 'auto', textAlign: 'center', bgcolor: '#f1f1f1', p: 4, borderRadius: 4, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#880404' }}>Payment Success!</Typography>
          <Typography variant="body1" sx={{ mb: 4, color: '#555' }}>
            Your payment was successful. Thank you for your purchase!
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: 8,
              padding: '12px 36px',
              backgroundColor: '#880404',
              fontWeight: 'bold',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: '#660303',
              },
            }}
            onClick={() => navigate('/dashboard')}
          >
            Go to My RepSeeker Dashboard
          </Button>
        </Box>
      ) : (
        <Box sx={{ maxWidth: 600, mx: 'auto', textAlign: 'center', bgcolor: '#f1f1f1', p: 4, borderRadius: 4, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold', color: '#880404' }}>Payment Failed!</Typography>
          <Typography variant="body1" sx={{ mb: 4, color: '#555' }}>
            Unfortunately, your payment was not successful. Please try again or contact support if the problem persists. You will be redirected to the dashboard shortly.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Checkout;
