import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material'; // Import the loading spinner
import {
  Container,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Badge,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { db, storage, auth } from '../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { CheckCircle as CheckCircleIcon, Close as CloseIcon } from '@mui/icons-material';
import { Editor, EditorState, RichUtils, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'draft-js/dist/Draft.css';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const AgencyCard = styled(Card)`
  margin: 8px;
  padding: 16px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.selected ? '#880404' : 'grey')};
  background-color: ${(props) => (props.selected ? '#ffcccb' : props.responded ? '#f1f1f1' : 'white')};
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
  `}
`;

const FilterContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 24px;
  background-color: #f4f4f4;
  border-radius: 12px;
`;

const StyledFormControl = styled(FormControl)`
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 5px;
  width: 200px;

  .MuiInputLabel-outlined {
    color: #880404;
    font-weight: bold;
    white-space: nowrap;
  }

  .MuiOutlinedInput-root {
    & fieldset {
      border-color: #880404;
    }
    &:hover fieldset {
      border-color: #660303;
    }
    &.Mui-focused fieldset {
      border-color: #880404;
    }
  }

  .MuiSelect-icon {
    color: #880404;
  }
`;

const TotalAgencies = styled(Typography)`
  background-color: #FFD700;
  color: #880404;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 25px;
  font-size: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const NewBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: #ff6f61;
    color: white;
    font-weight: bold;
    border-radius: 12px;
  }
`;

const EmailEditorContainer = styled(Box)`
  border: 2px solid #880404;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const ApplyForRep = () => {
  const [user] = useAuthState(auth);
  const [agencies, setAgencies] = useState([]);
  const [loadingSendEmails, setLoadingSendEmails] = useState(false);
  const [userData, setUserData] = useState({});
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailTemplate, setEmailTemplate] = useState(EditorState.createEmpty());
  const [resume, setResume] = useState(null);
  const [headshots, setHeadshots] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [filters, setFilters] = useState({
    new: '',
    emailed: '',
    responded: '',
    lookupKey: '',
  });
  const [profileLinks, setProfileLinks] = useState([
    { text: '', url: '' },  // Casting Networks Profile
  ]);  
  const [lookupKeyMapping] = useState({
    'Management_|_NY-f661620': 'Management | NY',
    'Management_|_LA-0e87320': 'Management | LA',
    'Commercial_|_LA-d534869': 'Commercial | LA',
    'Commercial_|_NY-315ac79': 'Commercial | NY',
    'Theatrical_|_LA-03f8995': 'Theatrical | LA',
    'Theatrical_|_NY-a37ccfa': 'Theatrical | NY',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgencies = async () => {
      if (user) {
        const userAgenciesCollection = collection(db, `users/${user.uid}/agencies`);
        const agencySnapshot = await getDocs(userAgenciesCollection);
        const agencyList = agencySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setAgencies(agencyList);
      }
    };

    const fetchEmailTemplate = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserData(userData);
          setFilters({...filters, lookupKey: userData?.purchased_plans[0]});
          setEmailSubject(userData.email_subject || `Seeking ` + lookupKeyMapping[userData?.purchased_plans[0]].split(' | ')[0] + ` Representation`);

          const defaultEmailTemplate = `Dear {{agent_first_name}},

My name is ${userData.firstName} and I’m seeking (rep type) representation.
          
I’m currently (list your union status here). My experience includes: (list all recent bookings/productions).
          
Special Skills: (List All sports, languages, hobbies, unique abilities).

[if applicable] Casting Relationships: (List all casting directors that you audition for frequently or have booked project with).

Please review my attached headshots and resume and let me know if you’re interested in scheduling a representation meeting.`;
          const blocksFromHTML = htmlToDraft(userData.email_template || defaultEmailTemplate);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          setEmailTemplate(EditorState.createWithContent(contentState));

          setResume(userData.resume || null);
          setHeadshots(userData.headshots || []);
          setProfileLinks(userData.profile_links || [{ text: '', link: '' }]);
        }
      }
    };

    fetchAgencies();
    fetchEmailTemplate();
  }, [user]);

  const handleSelectAgency = (id) => {
    setSelectedAgencies((prevState) =>
      prevState.includes(id)
        ? prevState.filter((agencyId) => agencyId !== id)
        : [...prevState, id]
    );
  };

  const handleAddProfileLink = () => {
    setProfileLinks([...profileLinks, { text: '', link: '' }]);
  };
  

  const handleProfileLinkChange = (index, field, value) => {
    const updatedLinks = [...profileLinks];
    updatedLinks[index][field] = value;
    setProfileLinks(updatedLinks);
  };  

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(emailTemplate, command);
    if (newState) {
      setEmailTemplate(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (style) => {
    setEmailTemplate(RichUtils.toggleInlineStyle(emailTemplate, style));
  };

  const handleSaveEmailTemplate = async () => {
    if (user) {
      const htmlContent = draftToHtml(convertToRaw(emailTemplate.getCurrentContent()));
      try {
        await updateDoc(doc(db, 'users', user.uid), { email_template: htmlContent, email_subject: emailSubject, profile_links: profileLinks, headshots: headshots, resume: resume });
        alert('Email template saved successfully!');
      } catch (error) {
        console.error('Error saving email template:', error);
      }
    }
  };

  const handleSendEmails = async () => {
    try {
      if (selectedAgencies.length > 3) {
        const confirmation = window.confirm(
          `You are about to send emails to ${selectedAgencies.length} agencies. This is a large volume of emails. Do you want to proceed?`
        );
        if (!confirmation) {
          return; // If the user cancels, exit the function
        }
      }
  
      setLoadingSendEmails(true);
      const emailTemplateHtml = draftToHtml(convertToRaw(emailTemplate.getCurrentContent()));
      
      // Save the profile links to the user's document
      await updateDoc(doc(db, `users/${user.uid}`), {
        email_template: emailTemplateHtml,
        email_subject: emailSubject,
        profile_links: profileLinks ,// Save the profile links
        headshots: headshots,
        resume: resume,
      });
  
      const payload = {
        agencyIds: selectedAgencies,
        uid: user.uid,
      };
  
      const response = await fetch('https://api-ippv36w6hq-uc.a.run.app/send-emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.status === 500) {
        throw new Error('Server error occurred');
      } else if (response.status === 200) {
        setLoadingSendEmails(false);
        alert('Emails sent successfully!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        setSelectedAgencies([]); // Clear selected agencies after sending
      }
    } catch (error) {
      setLoadingSendEmails(false);
      console.error('Error sending emails:', error);
      alert('An error occurred while sending emails.');
    }
  };
  
  

  const handleResumeUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      uploadFile(file, 'resume');
    } else {
      alert('Please upload a PDF file.');
    }
  };

  const handleHeadshotsUpload = (e) => {
    const files = e.target.files;
    if (files.length + headshots.length > 3) {
      alert('You can only upload a maximum of 3 headshots.');
      return;
    }
    Array.from(files).forEach((file) => uploadFile(file, 'headshots'));
  };

  const uploadFile = (file, type) => {
    setIsUploading(true);
    const filePath = `${user.uid}/${type}/${file.name}`;
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optional: Progress handling here
      },
      (error) => {
        console.error('Upload failed:', error);
        setIsUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        if (type === 'resume') {
          await updateDoc(doc(db, 'users', user.uid), { resume: downloadURL });
          setResume(downloadURL);
        } else if (type === 'headshots') {
          const updatedHeadshots = [...headshots, downloadURL];
          await updateDoc(doc(db, 'users', user.uid), { headshots: updatedHeadshots });
          setHeadshots(updatedHeadshots);
        }
        setIsUploading(false);
      }
    );
  };

  const deleteHeadshot = async (url) => {
    try {
      const fileRef = ref(storage, url);
      await deleteObject(fileRef);
      const updatedHeadshots = headshots.filter((h) => h !== url);
      await updateDoc(doc(db, 'users', user.uid), { headshots: updatedHeadshots });
      setHeadshots(updatedHeadshots);
    } catch (error) {
      console.error('Error deleting headshot:', error);
    }
  };

  const filteredAgencies = agencies.filter((agency) => {
    const isNew = agency.created_ts && Date.now() - agency.created_ts < 259200000; // 3 days in ms
    const isEmailed = Boolean(agency.emailed_ts);
    const isResponded = Boolean(agency.response_ts);
    const isInLookupKey = filters.lookupKey ? agency.lookup_key === filters.lookupKey : true;

    return (
      (filters.new === '' || (filters.new === 'Y' ? isNew : !isNew)) &&
      (filters.emailed === '' || (filters.emailed === 'Y' ? isEmailed : !isEmailed)) &&
      (filters.responded === '' || (filters.responded === 'Y' ? isResponded : !isResponded)) &&
      isInLookupKey
    );
  });

  return (
    <Container>
      <Typography variant="h3" mb={4} mt={4} sx={{ fontWeight: 'bold', color: '#880404' }} gutterBottom>
        Apply for Representation
      </Typography>
      <Box textAlign="center" mb={4}>
        <TotalAgencies>Total Agencies in My Lists: {agencies.length}</TotalAgencies>
      </Box>

      {userData?.purchased_plans && userData?.purchased_plans.length > 0 && (
        <Box mt={4} mb={4} p={3} bgcolor="#f5f5f5" borderRadius={2} boxShadow={2}>
          <Typography variant="h5" gutterBottom color="#880404" fontWeight="bold">
            Your Purchased Plans
          </Typography>
          <Grid container spacing={2} mt={1}>
            {userData.purchased_plans.map((plan, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card elevation={3} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#ffffff' }}>
                  <CardContent>
                    <Typography variant="body1" color="#333" textAlign="center" fontWeight="500">
                      {lookupKeyMapping[plan] || plan}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {userData?.purchased_plans && userData.purchased_plans.length < 6 && (
            <Box mt={3} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/purchase-lists')}
                sx={{
                  borderRadius: 8,
                  padding: '12px 36px',
                  backgroundColor: '#880404',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#660303',
                  },
                }}
              >
                Purchase More Lists
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Typography variant="h4" mb={4} mt={4} sx={{ fontWeight: 'bold', color: '#880404' }} gutterBottom>
        Select Agencies to Email
      </Typography>

      <FilterContainer>
        <Typography variant="h6" fontWeight="bold" color="#880404" mb={2} ml={5} mr={5}>
          Filters
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="stretch" width="100%">
        <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Filter by List</InputLabel>
            <Select
              value={filters.lookupKey}
              onChange={(e) => {setFilters({ ...filters, lookupKey: e.target.value }); setEmailSubject(`Seeking ` + lookupKeyMapping[e.target.value].split(' | ')[0] + ` Representation`)}}
              label="Filter by List"
            >
              <MenuItem value="">
                <em>No Filter</em>
              </MenuItem>
              {userData?.purchased_plans?.map((lookupKey) => (
                <MenuItem key={lookupKey} value={lookupKey}>
                  {lookupKeyMapping[lookupKey] || lookupKey}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Filter by New</InputLabel>
            <Select
              value={filters.new}
              onChange={(e) => setFilters({ ...filters, new: e.target.value })}
              label="Filter by New"
            >
              <MenuItem value="">
                <em>No Filter</em>
              </MenuItem>
              <MenuItem value="Y">Yes</MenuItem>
              <MenuItem value="N">No</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Filter by Emailed</InputLabel>
            <Select
              value={filters.emailed}
              onChange={(e) => setFilters({ ...filters, emailed: e.target.value })}
              label="Filter by Emailed"
            >
              <MenuItem value="">
                <em>No Filter</em>
              </MenuItem>
              <MenuItem value="Y">Yes</MenuItem>
              <MenuItem value="N">No</MenuItem>
            </Select>
          </StyledFormControl>
          <StyledFormControl variant="outlined" sx={{ mb: 2 }}>
            <InputLabel>Filter by Responded</InputLabel>
            <Select
              value={filters.responded}
              onChange={(e) => setFilters({ ...filters, responded: e.target.value })}
              label="Filter by Responded"
            >
              <MenuItem value="">
                <em>No Filter</em>
              </MenuItem>
              <MenuItem value="Y">Yes</MenuItem>
              <MenuItem value="N">No</MenuItem>
            </Select>
          </StyledFormControl>
        </Box>
      </FilterContainer>

      <Grid container spacing={2}>
        {filteredAgencies.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" align="center">
              No results matching your filters. Adjust your filters to view the rest.
            </Typography>
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/purchase-lists')}
                sx={{
                  borderRadius: 8,
                  p: 2,
                  backgroundColor: '#880404',
                  '&:hover': {
                    backgroundColor: '#660303',
                  },
                }}
              >
                Purchase More Lists
              </Button>
            </Box>
          </Grid>
        ) : (
          filteredAgencies.map((agency) => {
            const isEmailed = agency.emailed_ts;
            const isResponded = agency.response_ts;
            const isNew = agency.created_ts && Date.now() - agency.created_ts < 259200000; // 3 days in ms
            const isDisabled = isEmailed || isResponded;

            const colorCoding = {
              'Management_|_NY-f661620': '#ffcccc',
              'Management_|_LA-0e87320': '#cceeff',
              'Commercial_|_LA-d534869': '#c3e6cb',
              'Commercial_|_NY-315ac79': '#ffeeba',
              'Theatrical_|_LA-03f8995': '#d1ecf1',
              'Theatrical_|_NY-a37ccfa': '#f9d6d5',
            };

            return (
              <Grid item xs={12} sm={6} md={4} key={agency.id}>
                <AgencyCard
                  disabled={isDisabled}
                  selected={selectedAgencies.includes(agency.id)}
                  onClick={() => !isDisabled && handleSelectAgency(agency.id)}
                  sx={{ backgroundColor: colorCoding[agency.lookup_key] || 'white' }}
                >
                  {/* Add the agency.lookup_key tag at the top */}
                  <Typography
                    variant="overline"
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      backgroundColor: 'lightgray',
                      color: 'black',
                      py: 1,
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    {lookupKeyMapping[agency.lookup_key].split(' | ')[0]}
                  </Typography>
            
                  <NewBadge
                    badgeContent={isNew ? 'New' : null}
                    color="secondary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        top: 16,
                        left: 16,
                      },
                    }}
                  >
                    <CardContent sx={{ pt: isNew ? 5 : 2, px: 3, pb: 2 }}>
                      <Typography variant="h6">{agency.name}</Typography>
                      <Typography variant="body2">
                        Website: <a href={agency.url} target="_blank" rel="noopener noreferrer">{agency.url}</a>
                      </Typography>
                      {isEmailed && (
                        <Typography variant="body2" color="textSecondary">
                          Emailed on {new Date(agency.emailed_ts).toLocaleDateString()}
                        </Typography>
                      )}
                      {isResponded && (
                        <Typography variant="body2" color="textSecondary">
                          Responded on {new Date(agency.response_ts).toLocaleDateString()}
                        </Typography>
                      )}
                    </CardContent>
                  </NewBadge>
                </AgencyCard>
              </Grid>
            );
            
          })
        )}
      </Grid>

      <Box mb={4} mt={4}>
        <TextField
          label="Subject"
          fullWidth
          variant="outlined"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
        />
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Your Customizable Email Template
        </Typography>
        <Typography variant="body1" paragraph>
          After researching the companies above, select the talent reps you're interested in applying to. Next, fill out the email application template below, attach your materials (resume and headshots) , list any profile links and press send. Any and all replies will go directly to your personal gmail. Click on the Tracking tab to track your outgoing emails. 
          NOTE: Use "agent_first_name" with double braces wrapped around it anywhere in the email template to insert the agent's first name. We've shown you an example in the email's greeting.
        </Typography>
        <Box>
          <Button onClick={() => toggleInlineStyle('BOLD')} sx={{ color: '#880404' }}>
            B
          </Button>
          <Button onClick={() => toggleInlineStyle('ITALIC')} sx={{ color: '#880404' }}>
            I
          </Button>
          <Button onClick={() => toggleInlineStyle('UNDERLINE')} sx={{ color: '#880404' }}>
            U
          </Button>
        </Box>
        <EmailEditorContainer>
          <Editor
            editorState={emailTemplate}
            handleKeyCommand={handleKeyCommand}
            onChange={setEmailTemplate}
            placeholder="Compose your email..."
          />
        </EmailEditorContainer>
        <Box mt={4}>
  <Typography variant="h6" gutterBottom>
    Add Profile Links:
  </Typography>
  {profileLinks.map((profileLink, index) => (
    <Box key={index} display="flex" mb={2}>
      <TextField
        label="Link Text"
        placeholder="(ex. Casting networks profile)"
        value={profileLink.text}
        onChange={(e) => {
          const newProfileLinks = [...profileLinks];
          newProfileLinks[index].text = e.target.value;
          setProfileLinks(newProfileLinks);
        }}
        sx={{ mr: 2, width: '45%' }}
      />
      <TextField
        placeholder="(ex. https://mycastingprofile.com)"
        value={profileLink.link}
        onChange={(e) => {
          const newProfileLinks = [...profileLinks];
          newProfileLinks[index].link = e.target.value;
          setProfileLinks(newProfileLinks);
        }}
        sx={{ width: '45%' }}
      />
    </Box>
  ))}
  <Button variant="outlined" color="primary" onClick={handleAddProfileLink}>
    Add Another Profile Link
  </Button>
</Box>
<Box mt={4}>
        <Typography variant="h6" gutterBottom>
          {resume ? (
            <>
              Resume Uploaded <CheckCircleIcon style={{ color: 'green' }} />
              <Typography variant="body2" color="textSecondary">
                <a href={resume} target="_blank" rel="noopener noreferrer">
                  View Resume
                </a>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Have an Updated Resume? Upload Here (PDF Only)
              </Typography>
            </>
          ) : (
            'Upload Resume (PDF Only)'
          )}
        </Typography>
        <input accept="application/pdf" type="file" onChange={handleResumeUpload} disabled={isUploading} />
      </Box>

      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          {headshots.length === 0 ? (
            'Upload a Headshot (3 Max)'
          ) : (
            <>
              Headshot Uploaded <CheckCircleIcon style={{ color: 'green' }} />
            </>
          )}
        </Typography>
        {headshots.length > 0 && headshots.length < 3 && (
          <>
            <Typography variant="body2" color="textSecondary">
              Upload another headshot
            </Typography>
            <br />
          </>
        )}
        <input
          accept="image/*"
          type="file"
          multiple
          onChange={handleHeadshotsUpload}
          disabled={headshots.length >= 3 || isUploading}
        />
        {headshots.length > 0 && (
          <Box mt={2}>
            {headshots.map((url, index) => (
              <Box key={index} display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary" mr={2}>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    Headshot {index + 1}
                  </a>
                </Typography>
                <IconButton onClick={() => deleteHeadshot(url)} size="small" color="secondary">
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Box>
<Box mt={4} p={3} border="1px solid #ccc" borderRadius="8px" bgcolor="#f9f9f9">
  <Typography variant="h4" gutterBottom color="#880404">
    Email Preview
  </Typography>
  <Typography variant="h6" gutterBottom color="#000">
    Subject: {emailSubject || 'Your Email Subject Here'}
  </Typography>
  <br></br>
  <Box
    dangerouslySetInnerHTML={{
      __html: draftToHtml(convertToRaw(emailTemplate.getCurrentContent())),
    }}
  />
    {profileLinks.some(link => link.text && link.link) && (
    <Box mt={4}>
      {profileLinks.map((profileLink, index) => (
        profileLink.text && profileLink.link && (
          <Typography key={index} variant="body2">
            <a href={profileLink.link} target="_blank" rel="noopener noreferrer">
              {profileLink.text}
            </a>
          </Typography>
        )
      ))}
    </Box>
  )}
  <Box mt={4}>
  <Typography variant="body1">
    Best,
    <br />
    {userData.firstName} {userData.lastName}
  </Typography>
</Box>
</Box>

      <Box mt={2} textAlign="right">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveEmailTemplate}
            sx={{
              borderRadius: 8,
              padding: '12px 36px',
              backgroundColor: '#880404',
              fontWeight: 'bold',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: '#660303',
              },
            }}
          >
            Save Email Template
          </Button>
        </Box>
      </Box>

<Box mt={4} mb={4} textAlign="center">
  <Button
    variant="contained"
    color="primary"
    onClick={handleSendEmails}
    disabled={selectedAgencies.length === 0 || !resume || headshots.length === 0 || isUploading || loadingSendEmails}
    sx={{
      borderRadius: 8,
      padding: '12px 36px',
      backgroundColor: '#880404',
      fontWeight: 'bold',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: '#660303',
      },
    }}
  >
    {loadingSendEmails ? (
      <CircularProgress size={24} color="inherit" />  // Show spinner when loading
    ) : (
      'Send Outbound Emails'  // Show button text otherwise
    )}
  </Button>
</Box>
    </Container>
  );
};

export default ApplyForRep;
