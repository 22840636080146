import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase/config';
import repseekerLogo from '../assets/repseeker.jpeg';

const Navigation = ({ isAuthenticated }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      localStorage.setItem('loggedIn', "false");
      await signOut(auth);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && (auth.currentUser?.email === 'repseekerdev@gmail.com' || auth.currentUser?.email === 'repseekermain@gmail.com')) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [isAuthenticated]);

  const drawer = (
    <Box
      sx={{
        width: 250,
        backgroundColor: '#D3D3D3',
        height: '100%',
        color: '#880404',
      }}
    >
      <List>
        {isAuthenticated ? (
          <>
            <ListItem
              button
              component={Link}
              to="/dashboard"
              onClick={handleDrawerToggle}
              sx={{ padding: '12px 24px' }}
            >
              <DashboardIcon sx={{ color: '#880404', marginRight: 2 }} />
              <ListItemText primary="Dashboard" />
            </ListItem>
            {isAdmin && (
              <ListItem
                button
                component={Link}
                to="/admin-dashboard"
                onClick={handleDrawerToggle}
                sx={{ padding: '12px 24px' }}
              >
                <DashboardIcon sx={{ color: '#880404', marginRight: 2 }} />
                <ListItemText primary="Admin Dashboard" />
              </ListItem>
            )}
            <ListItem
              button
              onClick={() => {
                handleLogout();
                handleDrawerToggle();
              }}
              sx={{ padding: '12px 24px' }}
            >
              <ExitToAppIcon sx={{ color: '#880404', marginRight: 2 }} />
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              button
              component={Link}
              to="/login"
              onClick={handleDrawerToggle}
              sx={{ padding: '12px 24px' }}
            >
              <LoginIcon sx={{ color: '#880404', marginRight: 2 }} />
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/get-started"
              onClick={handleDrawerToggle}
              sx={{ padding: '12px 24px' }}
            >
              <PersonAddIcon sx={{ color: '#880404', marginRight: 2 }} />
              <ListItemText primary="Register" />
            </ListItem>
          </>
        )}
      </List>
      <Divider sx={{ borderColor: '#880404', margin: '8px 0' }} />
      <Box
        component="img"
        src={repseekerLogo}
        alt="RepSeeker Logo"
        sx={{
          display: 'block',
          width: '150px',
          margin: '16px auto',
          borderRadius: '8px',
        }}
      />
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        background: 'linear-gradient(90deg, #f5f5f5, #e0e0e0)',
        boxShadow: 'none',
        padding: '8px 16px',
        transition: 'background-color 0.3s ease',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', padding: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component={Link}
            to="/"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              marginRight: 2,
            }}
          >
            <Box
              component="img"
              sx={{ height: 50, marginRight: 2 }}
              alt="RepSeeker Logo"
              src={repseekerLogo}
            />
            <Typography
              variant="h6"
              sx={{
                color: '#880404',
                textDecoration: 'none',
                fontWeight: 'bold',
                paddingTop: '4px',
                transition: 'color 0.3s ease',
              }}
            >
              {isAuthenticated ? 'My RepSeeker Portal' : 'RepSeeker'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {isAuthenticated ? (
            <>
              <Button
                component={Link}
                to="/dashboard"
                sx={{
                  color: '#880404',
                  fontWeight: 'bold',
                  marginRight: 2,
                  padding: '6px 16px',
                  transition: 'color 0.3s ease',
                }}
              >
                Dashboard
              </Button>
              {isAdmin && (
                <Button
                  component={Link}
                  to="/admin-dashboard"
                  sx={{
                    color: '#880404',
                    fontWeight: 'bold',
                    marginRight: 2,
                    padding: '6px 16px',
                    transition: 'color 0.3s ease',
                  }}
                >
                  Admin Dashboard
                </Button>
              )}
              <Button
                onClick={handleLogout}
                sx={{
                  color: '#880404',
                  fontWeight: 'bold',
                  borderRadius: 8,
                  padding: '6px 16px',
                  backgroundColor: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button
                component={Link}
                to="/login"
                sx={{
                  borderRadius: 8,
                  padding: '6px 16px',
                  backgroundColor: '#FFFFFF',
                  color: '#880404',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Login
              </Button>
              <Button
                component={Link}
                to="/get-started"
                sx={{
                  borderRadius: 8,
                  padding: '6px 16px',
                  marginLeft: 2,
                  backgroundColor: '#FFFFFF',
                  color: '#880404',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Register
              </Button>
            </>
          )}
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' }, color: '#880404' }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 250,
            backgroundColor: '#f5f5f5',
            color: '#880404',
            padding: '16px',
            borderRadius: '8px 0 0 8px',
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navigation;
