import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Login from './components/Login';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import Home from './components/Home';
import Checkout from './components/Checkout';
import Dashboard from './components/Dashboard';
import PurchaseLists from './components/PurchaseLists';
import GetStarted from './components/GetStarted';
import PrepList from './components/PrepList';
import ApplyForRep from './components/ApplyForRep';
import TrackEmails from './components/TrackEmails';
import RedirectPage from './components/LoginRedirectPage';
import PrivateRoute from './routes/PrivateRoute';
import AdminDashboard from './components/AdminDashboard';
import GlobalStyles from './styles/GlobalStyles';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/config';

function App() {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <GlobalStyles />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Navigation isAuthenticated={!!user} />
        <Box sx={{ flex: '1 0 auto' }}>
          <Routes>
          <Route path="/" element={<Home />} /> {/* Root route always renders TermsOfUse */}

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
            <Route path="/login-redirect" element={<RedirectPage />} /> {/* Redirect Page Route */}
            <Route path="/get-started" element={user ? <Navigate to="/dashboard" /> : <GetStarted />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/purchase-lists" element={<PurchaseLists />} />
            <Route path="/dashboard" element={<PrivateRoute isAuthenticated={!!user} />}>
              <Route path="" element={<Dashboard />} />
              <Route path="prep-list" element={<PrepList />} />
              <Route path="apply-for-rep" element={<ApplyForRep />} />
              <Route path="track-emails" element={<TrackEmails />} />
            </Route>
            <Route path="/admin-dashboard" element={<AdminDashboard />} /> {/* Admin Dashboard Route */}
            <Route path="*" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
