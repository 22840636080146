import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc } from 'firebase/firestore';

const Login = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!loading && user) {
      checkUserInDatabase(user);
    }
  }, [user, loading]);

  const handleGoogleLogin = async () => {
    const state = encodeURIComponent(JSON.stringify({ "isLogin": "true" }));
    try {
      localStorage.setItem('loggedIn', "false");
      const response = await fetch(`https://api-ippv36w6hq-uc.a.run.app/get-google-auth-url?state=${state}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to get auth URL.');
      }

      const width = 500;
      const height = 600;
      const left = (window.screen.width / 2) - (width / 2);
      const top = (window.screen.height / 2) - (height / 2);

      const { authUrl } = await response.json();
      const popup = window.open(
        authUrl,
        "GoogleAuthPopup",
        `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
      );

      if (!popup) {
        throw new Error("Failed to open popup window.");
      }
      const interval = setInterval(() => {
        if (popup.closed) {
          clearInterval(interval);
          const loggedIn = localStorage.getItem('loggedIn');
          if (loggedIn === "true") {
            navigate('/dashboard');
          } else {
            setError(localStorage.getItem('error').toUpperCase() ?
              localStorage.getItem('error').toUpperCase() :
              "FAILED TO AUTHENTICATE USER")
            localStorage.setItem('error', "");
            setInterval(() => {
              setError("")
            }, 5000)
          }
        }
      }, 1000);

    } catch (error) {
      console.error("Error during Google sign-up:", error);
    }
  };

  const checkUserInDatabase = async (user) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        navigate('/dashboard');
      } else {
        alert('Your email is not registered. Please sign up first.');
        auth.signOut();
      }
    } catch (error) {
      console.error('Error checking user in database:', error);
    }
  };

  return (
    <Container>
      {error && (
        <div style={{
          width: "100%",
          padding: "10px",
          margin: "10px 0",
          backgroundColor: "#f8d7da",
          color: "#721c24",
          border: "1px solid #f5c6cb",
          borderRadius: "5px",
          textAlign: "center"
        }}>
          {error}
        </div>
      )}
      <Typography variant="h4" sx={{ mt: 5, mb: 2 }}>Login</Typography>
      <Button
        onClick={handleGoogleLogin}
        variant="contained"
        sx={{
          backgroundColor: '#880404',
          color: '#FFFFFF', // White text color
          '&:hover': {
            backgroundColor: '#660303', // Darker red for hover
          },
        }}
      >
        Login with Google
      </Button>
      <Typography variant="body2" sx={{ mt: 2, fontWeight: 'bold' }}>
        You are required to login with the Google account you created your account with.
        If you forgot the email you signed in with, reach out to support@repseeker.com.
      </Typography>
    </Container>
  );
};

export default Login;
