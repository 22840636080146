// RedirectPage.js
import React, { useEffect, useState } from 'react';
import { signInWithCustomToken } from "firebase/auth";
import { db, auth } from '../firebase/config';

const RedirectPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const state = queryParams.get('state');
        const code = queryParams.get('code');

        if (state && code) {
            exchangeAuthCode(code, state);
        } else {
            setError('Missing state or code in the URL');
            setLoading(false);
        }
    }, []);

    const handleAuthToken = async (token) => {
        try {
            const userCredential = await signInWithCustomToken(auth, token);
            console.log("User signed in:", userCredential.user);
        } catch (error) {
            console.error("Error signing in with custom token:", error);
            setError("Failed to authenticate user.");
        }
    };

    const exchangeAuthCode = async (code, state) => {
        try {
            const response = await fetch('https://api-ippv36w6hq-uc.a.run.app/exchange-auth-code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code, state }),
            });

            if (!response.ok) {
                if (response.status === 404) {
                    localStorage.setItem('error', "USER ACCOUNT DOES NOT EXISTS");
                } else {
                    localStorage.setItem('error', "FAILED TO AUTHENTICATE USER");
                }
                throw new Error('Failed to exchange auth code');
            }

            const data = await response.json();
            if (data.success) {
                await handleAuthToken(data.token)
                localStorage.setItem('formData', JSON.stringify(data.user));
                localStorage.setItem('loggedIn', "true");
            }
        } catch (error) {
            console.error('Error during code exchange:', error);
            setError('Error during code exchange');
        } finally {
            window.close();
        }
        setLoading(false);
    };


    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                        color: '#fff',
                        fontSize: '24px',
                    }}
                >
                    Loading..
                </div>
            )}
            {error && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '20px',
                        color: 'red',
                        fontSize: '18px',
                    }}
                >
                    {error}
                </div>
            )}

            <div>
                <p>Authenticating..</p>
            </div>
        </div>
    );
};

export default RedirectPage;
