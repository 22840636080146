import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCIgO-8VTubSxpE1-s-vobh9OacoWRA9_E",
  authDomain: "repseeker-22faf.firebaseapp.com",
  projectId: "repseeker-22faf",
  storageBucket: "repseeker-22faf.appspot.com",
  messagingSenderId: "1003128171178",
  appId: "1:1003128171178:web:6822b19772d95c1937bed3",
  measurementId: "G-L3DBVWQZF3"
};

// Export Firebase configuration directly
export default firebaseConfig;

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Export the Firestore and Auth instances
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
