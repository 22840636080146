import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider, Paper, Slide } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom Styled Components
const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#333',
  fontWeight: 'bold',
  textAlign: 'center',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#555',
  fontWeight: 'bold',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PrepList = () => {
  return (
    <Container>
      <br></br>
      <br></br>

      <br></br>

      <Title variant="h3">Representation Prep List</Title>
      <Typography variant="h5" gutterBottom align="center">
        Thank you for choosing Repseeker
      </Typography>
      <Typography variant="body1" paragraph align="center">
        The following information is meant to help increase your odds of signing with a talent representative.
        We've curated this guide after meeting with numerous talent representatives and identifying their most
        common preferences when being contacted and meeting with potential clients (talent).
      </Typography>

      <Section>
        <SubTitle variant="h6">Emailing Agents your materials</SubTitle>
        <Typography variant="body1" paragraph>
          Take some time and research the Agencies on the contact list you've purchased (we've provided websites for the majority of companies).
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>
            When narrowing down your favorites, some aspects to consider are the size of the representation company, number of years in business,
            talent roster size, talent they currently represent that are in your category (age range, ethnicity, resume), and recent bookings.
          </strong>
        </Typography>

        <List>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <StyledListItem>
              <IconWrapper>
                <CheckCircleIcon color="primary" />
              </IconWrapper>
              <StyledListItemText
                primary="A. Identify & Apply to your top choices first. We recommend applying to a maximum of 20 companies per week."
                secondary="Why?... Since agents and managers often have strict time frames when signing new talent, this allows you to:
                - Accept all proposed representation meetings without rescheduling 
                - Accept or Decline any representation offers quickly"
              />
            </StyledListItem>
          </Slide>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <StyledListItem>
              <IconWrapper>
                <CheckCircleIcon color="primary" />
              </IconWrapper>
              <StyledListItemText
                primary="B. Best days/times to apply for representation are Wednesday - Friday between 10am and 3pm"
              />
            </StyledListItem>
          </Slide>
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <StyledListItem>
              <IconWrapper>
                <CheckCircleIcon color="primary" />
              </IconWrapper>
              <StyledListItemText
                primary="C. Allow representatives five business days to respond after applying."
                secondary="Use the TRACK EMAIL tab above to view all emails opened or reviewed by recipients.
                IF you do not receive a reply within five (5) business days, assume the agency or management company does not see you as a fit for their current roster.
                Should you get a reply, respond immediately to schedule a meeting or with the requested materials (some representatives will send you sides to put yourself on tape before they decide on meeting with you)."
              />
            </StyledListItem>
          </Slide>
        </List>

        {/* New paragraph addition */}
        <Typography variant="body1" paragraph>
          <strong>Resubmitting to Agents/Managers that don't reply to your initial emails:</strong> 
          <br></br>
          When talent representatives don't reply to your emails it means you're not currently a fit for their client list.
          Since it's important to wait before re-applying to the same company, we've implemented an 8-week "Cool Down Period".
          Your ability to contact the agent/manager again will be available in your dashboard eight weeks from the date you originally emailed them. 
          This allows time for a representative's client list needs to potentially change and more importantly, time for you to update your materials.
          Updated headshots will occasionally elicit a different response from talent representatives. Additionally, updating your resume with new bookings (credits)
          and/or special skills could increase future replies from agents and managers.
        </Typography>

      </Section>

      <Section>
        <SubTitle variant="h6">Commonly Asked Meeting Questions</SubTitle>
        <Typography variant="body2" paragraph>
          The following questions are commonly asked by talent representatives when meeting with potential clients:
        </Typography>
        <List>
          {[
            "What are some of your acting career goals?",
            "How flexible is your current schedule for auditioning (in person & self tape) given a few hours notice?",
            "Are you willing to take new head shots?",
            "Which (if any) acting classes are you currently enrolled in?",
            "Do you create/produce your own content?",
            "What age range / role types are you interested in auditioning for?",
            "Are there any projects you're opposed to auditioning for?",
            "Commercially, are you open to Alcohol, Fast Food, Tobacco, or political ads?",
            "Theatrically, are you open to roles that require nudity?",
            "Are there any special skills we should be aware of?",
            "Do you have a valid passport?",
            "Are you building your social media (Instagram, Youtube, TikTok) following?"
          ].map((question, index) => (
            <Slide key={index} direction="up" in={true} mountOnEnter unmountOnExit>
              <StyledListItem>
                <IconWrapper>
                  <ArrowForwardIosIcon color="secondary" />
                </IconWrapper>
                <StyledListItemText primary={`${index + 1}. ${question}`} />
              </StyledListItem>
            </Slide>
          ))}
        </List>
      </Section>

      <Section>
        <SubTitle variant="h6">Questions to Ask Agents / Managers</SubTitle>
        <List>
          {[
            "What is your preferred communication method with your clients (text, calls, email)?",
            "How big is your current client list?",
            "Do you represent anyone similar to me (in my category)?",
            "How do you prefer your clients book out when unavailable?",
            "Do you like my current head shots or should I re-shoot with a photographer you recommend?",
            "What commission (percentage) do you charge your clients on bookings?",
            "Is representation at your company exclusive?",
            "What made you interested in taking a meeting with me?",
            "Which casting platforms do you need your clients to have profiles with?",
            "How long is your representation contract term?",
            "What is your policy on Self Submissions?",
            "How can I make myself more marketable?",
            "What type roles would you focus on submitting me for?"
          ].map((question, index) => (
            <Slide key={index} direction="up" in={true} mountOnEnter unmountOnExit>
              <StyledListItem>
                <IconWrapper>
                  <ArrowForwardIosIcon color="secondary" />
                </IconWrapper>
                <StyledListItemText primary={`${index + 1}. ${question}`} />
              </StyledListItem>
            </Slide>
          ))}
        </List>
      </Section>

      <Section>
        <SubTitle variant="h6">Industry Standard Terms & Definitions</SubTitle>
        <List>
          {[
            "Book Out - Notifying your representatives about future dates when you’ll be unavailable for auditions or bookings.",
            "Avail, Pin, “Watch and Advise”, Hold - After auditioning for a project, casting will ask their top choices to hold particular dates open, in case they decide to book you.",
            "Scale - The minimum rate SAG-AFTRA projects pay talent.",
            "SAG E (Sag Eligible) - You've met SAG-AFTRA's minimum requirements to join and are now qualified to become a member whenever you're ready.",
            "Fi Core - Financial Core refers to a legal carve-out that permits workers opposed to participating in a labor union to be employed under the benefits of a union's contracts without compelling them to be a member of that union.",
            "Exclusivity - When talent agrees to avoid working with competitive brands while being featured in a commercial project.",
            "Residuals - SAG/AFTRA royalty payments paid to a commercial actor based on amount of airings.",
            "Buyout - Usage fee paid to talent on Non-Union projects.",
            "Episodic - Linear television and streaming episodes.",
            "Co Star - A television role that supports one or more scenes.",
            "Guest Star - A television role that includes multiple scenes and a character arc.",
            "Recurring - A television role that appears in multiple episodes of a series.",
            "Series Regular - A television role that’s part of the main/core cast.",
            "Pilot - A standalone episode of a TV series used to sell the proof of concept of a new show to television network executives in hopes of receiving a green light to produce an entire series.",
            "Check Authorization Letter - A form used by agents and managers allowing them to collect and deposit fees on behalf of the talent they represent.",
            "Casting Platform - A website or app used by casting directors to post projects for talent and representatives to submit on.",
            "Self-Tape / Media Request - An audition request by casting for talent to record specific media, typically for TV, Film, and Commercial projects.",
            "New Media - Projects produced for initial release on a digital platform.",
            "OTT - “Over The Top” - Media provided by networks through online streaming platforms."
          ].map((definition, index) => (
            <Slide key={index} direction="up" in={true} mountOnEnter unmountOnExit>
              <StyledListItem>
                <IconWrapper>
                  <CheckCircleIcon color="primary" />
                </IconWrapper>
                <StyledListItemText primary={`${index + 1}. ${definition}`} />
              </StyledListItem>
            </Slide>
          ))}
        </List>
      </Section>

      {/* ... other sections */}
    </Container>
  );
};

export default PrepList;
